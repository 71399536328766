.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-xs {
    font-size: 12px;
    /* Ajuste conforme necessário */
}

.icon-sm {
    font-size: 16px;
    /* Ajuste conforme necessário */
}

.icon-md {
    font-size: 24px;
    /* Ajuste conforme necessário */
}

.icon-lg {
    font-size: 32px;
    /* Ajuste conforme necessário */
}

.icon-xl {
    font-size: 48px;
    /* Ajuste conforme necessário */
}